<template>
  <div style="width: 100%;">
    <el-input v-model="inputValue" @input="updateValue" :disabled="disabled" style="width:calc(100% - 100px);max-width: 450px;"></el-input>
    <el-button class="ml10" :disabled="disabled" @click="dialogVisableAccount('open')"><el-icon><Expand /></el-icon></el-button>

    <!--  账户信息 -->
    <el-dialog draggable class="dialog-one"
               v-model="dialogVisibleAccount"
               title="常用账户信息"
               width="1000px"
               :close-on-click-modal="false"
               destroy-on-close
               :append-to-body="true"
               @close="dialogAccountListClose('cancel')"
    >
      <el-input v-model="searchAccountName" placeholder="搜索账户名称" style="width:300px;" clearable></el-input>
      <el-button class="fr" type="normal" plain @click="dialogVisableAccount('add')">+ 添加账户</el-button>
      <el-table :data="accountList" class="task-table mt10" stripe height="500px"
                row-key="id"
                highlight-current-row
                @row-click="rowClick"
      >
        <el-table-column label="选择" width="55" align="center" fixed="left">
          <template #default="scope">
            <el-radio
                :label="scope.row.id"
                v-model="selectedAccount.id">{{ '' }}
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="accountName" label="账户名称"  width="200"/>
        <el-table-column prop="depositBank" label="开户行"  width="200"/>
        <el-table-column prop="accountNumber" label="账号"  width="200"/>
        <el-table-column prop="taxpayerCode" label="纳税人识别号"  width="200"/>
        <el-table-column prop="address" label="地址"  width="200"/>
        <el-table-column prop="phone" label="电话"  width="150"/>
        <el-table-column prop="userName" label="创建人"  width="100"/>
        <el-table-column
            fixed="right"
            label="操作" width="120">
          <template #default="scope">
            <template v-if="scope.row.userId==userId">
              <el-button @click="dialogVisableAccount('edit',scope.row)" type="primary" link size="small">编辑</el-button>
              <el-popconfirm
                  confirm-button-text='确定'
                  cancel-button-text='取消'
                  title="确定删除吗？"
                  @confirm="dialogVisableAccount('delete',scope.row)"
              >
                <template #reference>
                  <el-button type="danger" link size="small">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="cancel" @click="dialogAccountListClose('cancel')">取消</el-button>
        <el-button  type="normal" @click="dialogAccountListClose('sure')">确定</el-button>
      </span>
      </template>
    </el-dialog>

    <!--    新增-->
    <el-dialog draggable class="dialog-one"
               v-model="dialogAccountAdd"
               title="新建账户"
               width="700px"
               :close-on-click-modal="false"
               destroy-on-close
               :append-to-body="true"
               @close="dialogAccountAddClose('cancel')"
    >
      <el-form class="form-account" ref="formAccountAdd" :model="formAccountAdd" :rules="rules" label-width="120px">
        <el-form-item label="账户名称：" prop="accountName">
          <el-input v-model="formAccountAdd.accountName" />
        </el-form-item>
        <el-form-item label="开户行：" prop="depositBank">
          <el-input v-model="formAccountAdd.depositBank" />
        </el-form-item>
        <el-form-item label="账号：" prop="accountNumber">
          <el-input v-model="formAccountAdd.accountNumber" />
        </el-form-item>
        <el-form-item label="纳税人识别号：" prop="taxpayerCode">
          <el-input v-model="formAccountAdd.taxpayerCode" />
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="formAccountAdd.address" />
        </el-form-item>
        <el-form-item label="电话：" prop="phone">
          <el-input v-model="formAccountAdd.phone" />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="cancel" @click="dialogAccountAddClose('cancel')">取消</el-button>
        <el-button  type="normal" @click="dialogAccountAddClose('sure')">提交</el-button>
      </span>
      </template>
    </el-dialog>

    <!--    编辑-->
    <el-dialog draggable class="dialog-one"
               v-model="dialogAccountEdit"
               title="编辑账户"
               width="700px"
               :close-on-click-modal="false"
               destroy-on-close
               :append-to-body="true"
               @close="dialogAccountEditClose('cancel')"
    >
      <el-form class="form-account" ref="formAccountEdit" :model="formAccountEdit" :rules="rules" label-width="120px">
        <el-form-item label="账户名称：" prop="accountName">
          <el-input v-model="formAccountEdit.accountName" />
        </el-form-item>
        <el-form-item label="开户行：" prop="depositBank">
          <el-input v-model="formAccountEdit.depositBank" />
        </el-form-item>
        <el-form-item label="账号：" prop="accountNumber">
          <el-input v-model="formAccountEdit.accountNumber" />
        </el-form-item>
        <el-form-item label="纳税人识别号：" prop="taxpayerCode">
          <el-input v-model="formAccountEdit.taxpayerCode" />
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="formAccountEdit.address" />
        </el-form-item>
        <el-form-item label="电话：" prop="phone">
          <el-input v-model="formAccountEdit.phone" />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="cancel" @click="dialogAccountEditClose('cancel')">取消</el-button>
        <el-button  type="normal" @click="dialogAccountEditClose('sure')">提交</el-button>
      </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "ReceivingAccount",
  props:{
    //预定义
    disabled:Boolean,
    modelValue: [String, Number], // 接收表单的值
    formCreateInject: Object,
  },
  data(){
    return{
      userId:'',
      inputValue:this.modelValue,
      searchAccountName:'',
      accountList:[],
      selectedAccount:{
        id:''
      },
      dialogVisibleAccount:false,
      dialogAccountAdd:false,
      dialogAccountEdit:false,
      formAccountAdd:{
        accountName:'',
        depositBank:'',
        accountNumber:'',
        taxpayerCode:'',
        address:'',
        phone:'',
        userId:'',
      },
      formAccountEdit:{},
      rules:{
        accountName:[
          { required: true, message: '请填写账户名称', trigger: ['blur','change'] },
        ],
        depositBank:[
          { required: true, message: '请填写开户行', trigger: ['blur','change'] },
        ],
        accountNumber:[
          { required: true, message: '请填写账号', trigger: ['blur','change'] },
        ],
      },
    }
  },
  watch:{
    modelValue(n){
      //同步 value 的值
      this.inputValue=n;
    },
    searchAccountName(val){
      this.getAccountList();
    }
  },
  mounted() {
    this.userId=this.$store.state.userInfo.userId;
    this.getAccountList();
  },
  methods:{
    updateValue(newValue){
      // console.log(newValue);
      this.$emit('update:modelValue', newValue);
    },
    getAccountList(){
      let vm=this;
      vm.accountList=[];
      // vm.selectedAccount={
      //   id:''
      // };
      vm.$http.get(vm.$apiPath.paymentAccount,{
        accountName:vm.searchAccountName
      }).then(res=>{
        // console.log(res);
        if(res.status==200 && res.data.code==200){
          vm.accountList=res.data.data;
          if(vm.selectedAccount.id){
            for(let i=0;i<vm.accountList.length;i++){
              if(vm.selectedAccount.id==vm.accountList[i].id){
                vm.selectedAccount=JSON.parse(JSON.stringify(vm.accountList[i]));
                break;
              }
            }
          }
        }
      })
    },
    dialogVisableAccount(type,row){
      let vm=this;
      if(type=='open'){
        vm.dialogVisibleAccount=true;
      }else if(type=='add'){
        vm.dialogAccountAdd=true;
      }else if(type=='edit'){
        vm.formAccountEdit=JSON.parse(JSON.stringify(row));
        vm.dialogAccountEdit=true;
      }else if(type=='delete'){
        vm.deleteAccount(row);
      }
    },
    dialogAccountListClose(state){
      let vm=this;
      if(state=='sure'){
        // console.log(vm.selectedAccount);
        // console.log(vm.formCreateInject);
        if(vm.selectedAccount.id){
          // console.log(123);
          let relatedFieldList=vm.formCreateInject.rule.props.relatedField;
          if(relatedFieldList && relatedFieldList.length>0){
            for(let i=0;i<relatedFieldList.length;i++){
              vm.formCreateInject.api.setValue(relatedFieldList[i].formFieldId, vm.selectedAccount[relatedFieldList[i].apiField]);
            }
          }
        }
        // vm.formCreateInject.api.setValue('Fzgjm3zi40fravc', vm.selectedAccount.accountNumber);
        vm.dialogVisibleAccount=false;
      }else{
        vm.dialogVisibleAccount=false;
      }
    },
    rowClick(val){
      // console.log(val);
      this.selectedAccount = JSON.parse(JSON.stringify(val));
      // console.log(this.selectedAccount);
    },
    dialogAccountAddClose(state){
      let vm=this;
      if(state=='sure'){
        // console.log(vm.formAccountAdd);
        vm.$refs['formAccountAdd'].validate((valid) => {
          if(valid){
            vm.formAccountAdd.userId=vm.userId;
            vm.$http.post(vm.$apiPath.paymentAccount,vm.formAccountAdd).then(res=>{
              if(res.status==200 && res.data.code==200){
                vm.getAccountList();
                vm.$refs['formAccountAdd'].resetFields();
                vm.dialogAccountAdd=false;
              }
            })
          }
        })
      }else{
        // vm.$refs['formAccountAdd'].resetFields();
        vm.dialogAccountAdd=false;
      }
    },
    dialogAccountEditClose(state){
      let vm=this;
      if(state=='sure'){
        // console.log(vm.formAccountEdit);
        vm.$refs['formAccountEdit'].validate((valid) => {
          if(valid){
            vm.$http.put(vm.$apiPath.paymentAccount+'/'+vm.formAccountEdit.id,vm.formAccountEdit).then(res=>{
              if(res.status==200 && res.data.code==200){
                vm.getAccountList();
                vm.$refs['formAccountEdit'].resetFields();
                vm.dialogAccountEdit=false;
              }
            })
          }
        })
      }else{
        vm.$refs['formAccountEdit'].resetFields();
        vm.dialogAccountEdit=false;
      }
    },
    deleteAccount(row){
      let vm=this;
      vm.$http.delete(vm.$apiPath.paymentAccount+'/'+row.id).then(res=>{
        if(res.status==200 && res.data.code==200){
          vm.getAccountList();
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-account{
  .el-form-item{
    margin-bottom: 20px;
  }
}
</style>
